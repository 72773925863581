// extracted by mini-css-extract-plugin
export var bookFlash = "page-module--bookFlash--ogwnQ";
export var bullets = "page-module--bullets--Ec+ZU";
export var cta = "page-module--cta--cDzZN";
export var easyPanel = "page-module--easyPanel--BShX2";
export var giftPanel = "page-module--giftPanel--YruS6";
export var hero = "page-module--hero--agVNq";
export var heroContent = "page-module--heroContent--NX1Lt";
export var icon = "page-module--icon--corwy";
export var intro = "page-module--intro--blo+2";
export var launchFooter = "page-module--launchFooter--dYy1l";
export var launchFooterInner = "page-module--launchFooterInner--rfGzz";
export var panel = "page-module--panel--DLTw1";
export var panelImg = "page-module--panelImg--DXhoz";
export var panelsCol = "page-module--panelsCol--oobLN";
export var snowGlobe = "page-module--snowGlobe--JP4dX";
export var starBook = "page-module--starBook--NO52J";