import React, { useState } from "react";
//import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import * as Icon from 'react-feather';
import { Container, Row, Col } from 'react-bootstrap';
////import 'bootstrap/dist/css/bootstrap-grid.min.css';;

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import Layout from '../../components/layout';
import { Modal } from '../../components/modal';

import * as styles from '../plan-your-book/plan-your-book.module.scss';
import * as pageStyles from './page.module.scss';

import bookFlashImg from '../../images/christmas/book-flash.png';
import giftPanelImg from '../../images/christmas/present.png';
import easyPanelImg from '../../images/christmas/class.png';
import starcastingImg from '../../images/plan-your-book/starcasting@2x.png'
import storyStartImg from '../../images/christmas/ipad-step1.png'
import feedbackImg from '../../images/christmas/step2.png'
import starbookImg from '../../images/christmas/starbook.png';

const ChristmasPage=({ location }) => {

    const [joinModal, setJoinModal] = useState(false);

    return(
        <>
            <Helmet>
                <title>Publish your Christmas Book</title>
            </Helmet>
            <Layout location={location}>
                <div className={pageStyles.hero}>
                    <Container>
                        <Row>
                            <Col md={{span:8, offset:2}} lg={{span:7, offset:0}} className={pageStyles.heroContent}>
                                <h1 className="headingHero">Make it a December to Remember!</h1>
                                <div className={pageStyles.snowGlobe}></div>
                                <h2 className="headingSmall">Celebrate the festive season by writing a Christmas book with your class.</h2>
                                <button onClick={ () => setJoinModal(true) } className={`primary-btn ${pageStyles.cta}`}>Get Started</button>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container>
                    <Row className={pageStyles.intro}>
                        <Col xs={12} md={4}>
                            <img src={bookFlashImg} alt="Christmas Joy Book" className={pageStyles.bookFlash} />
                        </Col>
                        <Col xs={12} md={8} lg={6} >
                            <h3>We all need a bit of joy this year</h3>
                            <p>With the theme of Christmas Joy, use BoomWriter UK to create your class collection of short stories or poems. It’s a perfect way to celebrate the season and engage your young writers.</p>
                            <p className="standout fontWeight-bold">Complete your book before 27 Nov for Christmas delivery.</p>
                        </Col>
                    </Row>
                    <Row as="ul" className={pageStyles.bullets}>
                        <Col as="li"><Icon.CheckCircle className={pageStyles.icon} /> <h4 className="headingSmall mb-0">Works Remotely</h4></Col>
                        <Col as="li"><Icon.CheckCircle className={pageStyles.icon} /> <h4 className="headingSmall mb-0">Any Device</h4></Col>
                        <Col as="li"><Icon.CheckCircle className={pageStyles.icon} /> <h4 className="headingSmall mb-0">Engaged Pupils</h4></Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className={pageStyles.panelsCol}>
                            <div className={pageStyles.giftPanel}>
                                <img src={giftPanelImg} alt="Gift" className={pageStyles.panelImg} />
                                <h4>The Perfect Gift<br/> Only £7.99/copy + p&p</h4>
                                <p>Spread some joy with the perfect Christmas gift to take home for your children and their families.</p>
                                <p>Parents can easily purchase direct from our store or your school purchase a class set.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className={pageStyles.panelsCol}>
                            <div className={pageStyles.easyPanel}>
                                <img src={easyPanelImg} alt="Class" className={pageStyles.panelImg} />
                                <h4>Published In A Day!</h4>
                                <p>You can easily complete the book in a single day. Of course you can take longer - it fits around your class needs.</p>
                                <p>This writing event will be the highlight of the term and a great way to bring the class together.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="py-1 pb-1">
                            <h4 className="headingHero txt-center mb-0">It's easy and quick</h4>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={styles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.25" duration={200}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`d-md-none ${styles.line} ${styles.lineV} ${styles.lineVHalfTop} ${styles.lineMid} ${styles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0" duration={300}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`d-md-none ${styles.line} ${styles.lineW} ${styles.lineWHalfRight} ${styles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.425" duration={270}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`d-none d-md-block ${styles.line} ${styles.lineV} ${styles.lineVHalfTop} ${styles.lineRight} ${styles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.15" duration={250}>
                                        <Tween from={{ width: "0%" }} to={{ width: "100%" }}>
                                            <div className={`d-none d-md-block ${styles.line} ${styles.lineW} ${styles.lineRight} ${styles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="flex-column flex-md-row align-items-center pb-5">
                                <Col xs="auto" md={{span:"auto", order:2}} className="mb-1 mb-md-0">
                                    <div className={styles.iconHolder}>
                                        <Icon.Eye className={styles.icon} />
                                    </div>
                                </Col>
                                <Col md={{order:1}} className="text-center text-md-right">
                                    <h2 className="headingSmall highlight">Story or Poems</h2>
                                    <p>You are in complete control of what you want your pupils to write.</p>
                                </Col>
                                <Col xs={{}} md={7} className="ml-n6 mt-2 ml-sm-0 m-md-0">
                                    <img className={`mx-sm-auto mx-md-0 d-block ${styles.image1}`} src={storyStartImg} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={styles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.3" duration={275}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${styles.line} ${styles.lineV} ${styles.lineLeft}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0" duration={300}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`${styles.line} ${styles.lineW} ${styles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="align-items-center py-5">
                                <Col className="p-0">
                                    <Row className="flex-column flex-sm-row m-0 align-items-sm-center m-0">
                                        <Col xs="auto">
                                            <div className={styles.iconHolder}>
                                                <Icon.Edit3 className={styles.icon} />
                                            </div>
                                        </Col>
                                        <Col className="text-left light-bg py-1 py-sm-0">
                                            <h2 className="headingSmall highlight">Go Write!</h2>
                                            <p>Your class completes the writing task. You can provide feedback to them digitally using BoomWriter UK to support their work or reward their efforts!</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mr-n4 m-sm-0 text-center">
                                    <img className={styles.image2} src={feedbackImg} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={styles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.4" duration={600}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`${styles.line} ${styles.lineV} ${styles.lineVFull} ${styles.lineMid}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="pt-5">
                                <Col className="p-0 light-bg">
                                    <Row className="mx-0 flex-column justify-content-center align-items-center">
                                        <Col xs="auto" className="mb-1">
                                            <div className={styles.iconHolder}>
                                                <Icon.Star className={styles.icon} />
                                            </div>
                                        </Col>
                                        <Col md={9} lg={7} xl={6} className="text-center mx-auto">
                                            <h2 className="headingSmall highlight">Starcast</h2>
                                            <p>This is where the magic happens! A simple and automated peer assessment of other pupils’ anonymised work.</p>
                                        </Col>
                                        <Col className="text-center mt-2">
                                            <img className={styles.image3} src={starcastingImg} alt="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={styles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="onCenter" duration={300}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${styles.line} ${styles.lineV} ${styles.lineMid}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="pt-5">
                                <Col className="p-0 light-bg">
                                    <Row className="flex-column justify-content-center align-items-center mx-0">
                                        <Col xs="auto" className="mb-1">
                                            <div className={styles.iconHolder}>
                                                <Icon.Users className={styles.icon} />
                                            </div>
                                        </Col>
                                        <Col md={9} lg={7} xl={6} className="text-center mx-auto pb-1">
                                            <h2 className="headingSmall highlight">That's it, Published!</h2>
                                            <p>Every child’s writing is included in the book. Send home our included letters so parents can purchase copies.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>

                <div className={pageStyles.launchFooter}>
                    <div className={pageStyles.launchFooterInner}>
                        <img src={starbookImg} className={pageStyles.starBook} alt="" />
                        <div>
                            <h2 className="headingHero">Hurry!</h2>
                            <p className="heading">Complete your book before 27 Nov for Christmas delivery.</p>
                            <p>Create your school account and get your class writing today.</p>
                            <button onClick={ () => setJoinModal(true) } className="reversed-btn mt-1 mb-1 d-inline-block">Get Started</button>
                        </div>
                    </div>
                </div>

                { joinModal && (
                    <Modal closeModal={() => setJoinModal(false)}>
                        <div className="loginModal">
                            <h2 className='loginModalTitle'>Start Your Book</h2>
                            <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="authLink authLinkTeacher">
                                <div className='authLinkTeacher'>
                                    <h3>Teachers & Schools</h3>
                                    <p>Create your school account & get started</p>
                                </div>
                                <Icon.ArrowRight />
                            </a>

                        </div>
                    </Modal>
                )}

            </Layout>

        </>
    )
}

export default ChristmasPage